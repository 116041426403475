import React from "react"
import SEO from "../components/seo"
import useScript from "../hooks/use-script"
import { withPrefix } from "gatsby"
import Layout from "../components/layout"
const PrivacyPolicy = (): JSX.Element => {
  useScript(withPrefix("js/show-on-scroll.js"))

  const schema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "WebPage",
        url: "https://documentesign.com/privacy-policy/",
        name: "Privacy Policy | Document eSign",
        isPartOf: {
          "@type": "WebSite",
          "@id": "https://documentesign.com/privacy-policy/#website",
        },
        datePublished: "2021-02-16T07:56:57+00:00",
        dateModified: "2021-04-28T16:44:56+00:00",
        description:
          "Document eSign takes your data privacy seriously. Our privacy policy explains who we are, how we collect, share and use Personal Information.",
        inLanguage: "en-US",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://documentesign.com/privacy-policy/"],
          },
        ],
      },
    ],
  }
  return (
    <>
      <Layout rawData={{ className: "sticky-header" }}>
        <SEO
          title="Privacy Policy | Document eSign"
          description="Document eSign takes your data privacy seriously. Our privacy policy explains who we are, how we collect, share and use Personal Information."
          schemaMarkup={schema}
        />
        <div className="intro contact-intro bg-blue-light ani-in show-on-scroll">
          <div className="container text-center">
            <h1 className="text-center pt-5 mt-5">Privacy Policy</h1>
          </div>
        </div>
        <div className="bg-lights py-5">
          <div className="container">
            <h3 className="mb-4 mt-3">
              <strong>Our Privacy Policy</strong>
            </h3>

            <p>
              Document eSign is committed to protecting your privacy and
              personal data. We process personal data in compliance with the
              privacy and data protection laws of the General Data Protection
              Regulation (GDPR), where Document eSign is established. In this
              privacy statement, we describe how we deal with personal data in
              the context of providing document eSign’s services to you.
            </p>

            <p>
              Document eSign takes your privacy very seriously. We ask that you
              read this Privacy Policy carefully as it contains important
              information about what to expect when Document eSign collects
              personal information about you and how Document eSign manages your
              personal information.
            </p>

            <p>
              We are committed to safeguarding the privacy of the users of our
              website (hereinafter the “User(s)” or “You”), who can use the
              Document eSign service (hereinafter the “Service”) through the
              Website on any device (hereinafter the “Device”), such as, but
              with no limitation to, desktop computers, laptop computers and
              tablets as well as mobile phones.
            </p>

            <p>
              This Privacy Policy applies to the information We collect about
              visitors to the Website and Users who use the Service by
              contracting the Service.
            </p>

            <p>
              We want to assure you that your information and data is safe with
              Us. We do not sell your information to anyone whatsoever. We
              ensure complete secrecy and privacy of your data entrusted to Us.
            </p>

            <p>
              The Service is not intended for use by persons under 18 years of
              age. In any case of such a person providing their personal
              information We reserve the right to remove it from the Website and
              the Service.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Access to your information</strong>
            </h3>

            <p>
              Under the GDPR, you have certain rights, such as with regard to
              rectification, erasure or blocking of personal data that we
              process. Document eSign has appointed a data protection officer
              (DPO). If you have any queries in relation to such rights or need
              to contact our DPO, please contact us at
              contact@documentesign.com.
            </p>

            <h4>
              <strong>What do we collect?</strong>
            </h4>

            <p>
              Use of Document eSign’s services implies that certain personal
              data is shared between users exchanging a signed document. After
              completing a signing process, Document eSign will send a signing
              log to both the user that initiated the signing process and to any
              users that have signed the document or are in another way related
              to the Document eSign.&nbsp;
            </p>

            <p>
              This signing log may contain names, email addresses, IP addresses
              and timestamps of all users involved, and may also contain certain
              additional information, such as location data or domain names. All
              changes to the document, initiated by users, such as text, dates
              and signatures, will be included in the signing log.
            </p>

            <p>
              Your name and email address may also have been provided to us by
              someone who sent you a Document eSign. If you log into your
              Document eSign account using Facebook, Google or other services,
              these services may share additional information about you in
              accordance with their privacy policy.
            </p>

            <p>
              During the signing process, we may provide users involved with
              information about the status of the signing process, such as
              whether a Document eSign has been delivered, viewed, downloaded
              and/or it has resulted in a signature. These events may also be
              included in the signing log.
            </p>

            <h4>
              <strong>How do we collect?</strong>
            </h4>

            <p>
              When you use the Document eSign services and website, your
              computer will automatically provide us with certain information
              about your computer hardware and software, such as IP addresses,
              operating system, browser type, location data provided by your
              browser, domain names, access times, timestamps and referring
              websites.
            </p>

            <p>
              The intent of Document eSign for collecting and processing
              information is first and foremost for providing our services.
              Secondly, we may use such information for the operation of our
              website and services, to maintain the quality thereof, and to
              obtain statistics regarding the use of the website and services.
              We may also use it to show to free users advertisements – that
              help keep our services free – and to send information about the
              products and services of our partners. Paying or registered
              Document eSign users will receive information (for example a
              newsletter) about our services. However, non-registered Document
              eSign users that originate from paying users will not receive
              information about our services or about the products and services
              of our partners.
            </p>

            <p>
              Document eSign may share personal data with approved
              subcontractors. For a list of these approved subcontractors we
              refer to Document eSign’s Data Processing Agreement. To review and
              sign the updated Data Processing Agreement.
            </p>

            <h4>
              <strong>Product checkout</strong>
            </h4>

            <p>
              We use 2Checkout (www.2checkout.com), a secure payment service, to
              process your payments. 2Checkout collects all the information with
              regard to your payment:
            </p>

            <ul>
              <li>Full name</li>
              <li>Address</li>
              <li>Email</li>
              <li>Credit card number</li>
              <li>Card expiration date</li>
              <li>Card security code</li>
              <li>Card holder name</li>
            </ul>

            <p>
              In the case of MasterCard and VISA, the credit card data is not
              stored on 2Checkout servers. If the cards are issued under the
              brand of other organizations, this information is stored encrypted
              using popular encryption algorithms and 2Checkout own algorithms.
            </p>

            <p>
              In case of other payment methods other information required for
              payment can be requested.
            </p>

            <p>
              After the payment is complete, Document eSign would be able to
              view your personal information, such as, but not limited to, your
              full name, address and last four digits of the credit or debit
              card the payment was made with.
            </p>

            <p>
              While using the Service We automatically record information from
              your device and its software as well as your activity using the
              Service, including, but with no limitation to, your Device
              Internet Protocol address (hereinafter the “IP address”), Device
              type, language, the Web page visited before and after you were
              redirected or came to the Website, timezone, identification
              numbers associated with your Device(s), browser type and version,
              your locale preferences and other interactions with the Service.
              We log this information and use it in order to improve the
              functionality of the Service.
            </p>

            <p>
              We use SendGrid service (www.sendgrid.com), an email service
              provider, for delivering email messages. SendGrid has a strict
              policy about how they store and protect email addresses and
              content of email messages. For information about SendGrid’s
              privacy policy.
            </p>

            <h4>
              <strong>How is this information secured?</strong>
            </h4>

            <p>
              The criteria used to determine the period of storage of personal
              data are the legal retention periods. After the expiration of that
              period, the corresponding data will be deleted, as long as it is
              no longer necessary for the fulfillment of the contract or the
              start of a new contract or required as part of the Document eSign
              services.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Policy Amendments</strong>
            </h3>

            <p>
              Document eSign may alter or amend this privacy policy from time to
              time. If we do, we will make reasonable efforts to inform our
              users of such changes through Document eSign’s website. If you
              subsequently continue to use Document eSign services, this will be
              understood to imply that you accept the altered or amended policy.
              In the event that you do not wish to accept the alterations or
              amendments, please stop using the services and delete any accounts
              you may have created on our systems.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>
                How do we collect and use the information about You
              </strong>
            </h3>

            <p>
              We gather the Information about you through the Website. We might
              use this Information to allow Us to:
            </p>

            <ul>
              <li>Provide You with the Service</li>
              <li>Administer the Website and business</li>
              <li>Create your User Account</li>
              <li>
                Communicate with you on any matter related to your User Account
                and the provision of the Service
              </li>
              <li>
                Send You communications related to your User Account and
                payments
              </li>
              <li>
                Provide third parties with statistical information about the
                Users of the Website (those third parties will not be able to
                identify any individual User from that information)
              </li>
              <li>Personalize the Website and the Service for You</li>
              <li>
                Send You notifications such as software updates or notifications
                related to your purchases, changes to our policies, or changes
                to the Service
              </li>
              <li>
                Send You marketing communications relating to Our business
                further specified in this Privacy Policy
              </li>
              <li>Send You non-marketing commercial communications</li>
              <li>
                Send statements and payment reminders to You, and collect
                payments from You
              </li>
              <li>
                Verify compliance with the Terms of Service governing the use of
                the Website
              </li>
              <li>
                Deal with enquiries and complaints made by or about You relating
                to the Website and the Service
              </li>
              <li>Keep the Website secure and prevent fraud</li>
            </ul>

            <p>
              We also save the signatures You create by signing the documents in
              a digitalized form in order to facilitate the use of the Service
              so You do not have to recreate your signature every time You sign
              a documents as well as to prove that a particular signature has
              been created by a particular person in an event of a potential
              conflict.
            </p>

            <p>
              We may retain your Information for any future orders and in order
              to contact You about other products and services We think may be
              of interest to You. You can opt out from receiving these
              communications by unsubscribing from our newsletter emails or
              contacting Us at contact@Documentesign.com.
            </p>

            <p>
              When you invite another person to sign the document via the
              Service, you may provide us with that person&#8217;s email
              address, which would be stored by Us. Likewise, other people may
              provide Us with your email address in a similar manner.
            </p>

            <p>
              From time to time We may provide your Information to our customer
              service agencies for research and analysis purposes so that We can
              monitor and improve the Service. This Information will not include
              any information You might be identified by. We, Our agents and
              sub-contractors may also contact You by post, email or telephone
              to ask You for your feedback and comments on Our services.
            </p>

            <p>
              We will disclose Information about You only when the disclosure is
              required or authorized by law or by this Privacy Policy or when We
              determine that it is reasonably necessary to protect other Users.
              At times your personal information may be disclosed to strategic
              partners and service providers that work with Us to provide or
              market the Service; for instance We use third parties to process
              credit card details for payment purposes.
            </p>

            <p>
              We will use contractual or other means to provide a comparable
              level of protection while the information is being processed by a
              third party.
            </p>

            <p>
              If your personal information is at any time merged with your
              non-personal information, such merged information will be treated
              as personal information under this Privacy Policy for as long as
              it remains merged.
            </p>

            <p>
              “Non-Personal Information” is information that does not permit
              identification of any specific person. We may collect, use,
              transfer, and disclose Non-Personal Information, (not including
              your Private Files).
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Third party links</strong>
            </h3>

            <p>
              Occasionally, at our discretion, We may include or offer
              third-party products or services on our website. These third-party
              sites have separate and independent privacy policies and are out
              of Our control. We therefore have no responsibility or liability
              for the content and activities of these linked sites and therefore
              recommend to read terms and policies thereon. Nonetheless, We seek
              to protect the integrity of our site and welcome any feedback
              about these sites.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Updating and Correcting your Information</strong>
            </h3>

            <p>
              You warrant that the Information which You are required to provide
              when you register with Us is true, accurate, current and complete
              in all respects. You agree not to impersonate any other person,
              whether legal or natural or to use a fictitious name or a name
              that You have no authorization to use. We want to ensure that your
              personal information is accurate and up to date. If any of the
              Information that You have provided to Document eSign changes or if
              You wish to cancel your User Account, please let Us know the
              correct details by sending an email to contact@documentesign.com.
              You may ask Us, or We may ask You, to correct Information You or
              We believe might be inaccurate, and You may also ask Us to remove
              Information which is inaccurate.
            </p>

            <p>
              Your personal information may be retained for as long as it is
              necessary to comply with this Privacy Policy unless a longer
              retention period is required or permitted by law.
            </p>

            <p>
              You may exercise your rights of access, request of correction,
              erasure and objection or revoke your consent for receipt of
              commercial communications by sending an email to
              contact@documentesign.com.
            </p>

            <p>
              You have the right to request a copy of the Information that We
              hold about You. If You would like a copy of some or all of your
              personal information, please send an email to
              contact@documentesign.com.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>Cookies</strong>
            </h3>

            <p>
              If you use our website or services, we may place a cookie (a piece
              of data) on your hard drive in order to enable the use of our
              services, to simplify and to obtain statistics regarding the use
              of our website and services.
            </p>

            <h3 className="mb-4 mt-3">
              <strong>How to contact Document eSign </strong>
            </h3>

            <p className="mb-5">
              We welcome your views and suggestions about our Website, Service,
              Products, and this Privacy Policy. If You would like to contact us
              with any queries or comments please send an email to{" "}
              <a href="mailto:contact@documentesign.com">
                contact@documentesign.com
              </a>
              .
            </p>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PrivacyPolicy
